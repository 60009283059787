import { useMemo } from 'react';
import { accountApi } from '@netiva/classifieds-api';

import { useAppSelector } from '@/store';
import { AdAttribute } from '@/store/ad/types';

export const useAdAttributes = () => {
  const { categoryId } = useAppSelector((state) => state.ad);
  const { data: attributesData, isLoading } = accountApi.useGetAccountAttributes(
    { categoryId: categoryId },
    { skip: !categoryId }
  );
  const attributes = useMemo<AdAttribute[]>(
    () => (attributesData?.attributes as AdAttribute[]) || [],
    [attributesData?.attributes]
  );

  return { attributes, isLoading };
};
